import $ from "jquery";
import "slick-carousel";

let previousWidth = window.innerWidth;

// Adjust the height of the slides to match the tallest slide
function initHeightListener() {
  if ($(".js-image-slider").length === 0) {
    return;
  }

  // Wait until all images are fully loaded
  //waitForImagesToLoad(".js-image-slider img", adjustSlideHeights);

  // Adjust heights on window resize
  $(window).on("resize", function () {
    const currentWidth = window.innerWidth;

    if (currentWidth !== previousWidth) {
      previousWidth = currentWidth; //Updates previous width 
      adjustSlideHeights(); // Call the function if width changes
    }
  });
}

function adjustSlideHeights() {
  let maxHeight = 45;
  // Find the tallest slide
  $(".js-image-slider .slick-slide").each(function () {
    const slideHeight = $(this).height();
    if (slideHeight > maxHeight) {
      maxHeight = slideHeight;
    }
  });

  // Apply the tallest height to all slides
  $(".js-image-slider .image-slider--slide").css("height", maxHeight + "px");
}

function waitForImagesToLoad(selector, callback) {
  const $images = $(selector);
  let imagesLoaded = 0;

  $images.each(function () {
    // Ensure the callback runs only after all images are loaded
    if (this.complete) {
      imagesLoaded++;
      if (imagesLoaded === $images.length) {
        callback();
      }
    } else {
      $(this).on("load", function () {
        imagesLoaded++;
        if (imagesLoaded === $images.length) {
          callback();
        }
      });
    }
  });
}

//$(document).ready(initHeightListener);
